#navbar {
	position: -webkit-sticky;
	position: sticky;
	top: -1px;
	z-index: 12;
}

.navbar {
	position: relative;
	display: flex;
	justify-content: space-around;
	align-items: center;
	min-height: 8vh;
	font-family: "Poppins", sans-serif;
	background-color: #007fff;
}

.logo {
	color: rgb(228, 228, 228);
	text-transform: uppercase;
	letter-spacing: 5px;
	font-size: 20px;
}

.nav-links {
	display: flex;
	justify-content: space-around;
	width: 50%;
}

.nav-links li {
	list-style: none;
	color: rgb(228, 228, 228);
	text-decoration: none;
	letter-spacing: 3px;
	cursor: pointer;
}

.nav-links li a {
	color: rgb(228, 228, 228);
	text-decoration: none;
	letter-spacing: 3px;
	cursor: pointer;
}

.burger {
	display: none;
}

.burger-wraper {
	display: none;
}

.burger div {
	width: 25px;
	height: 3px;
	background-color: rgb(228, 228, 228);
	margin: 5px;
	transition: all 0.3s ease;
}

@media screen and (max-width: 1064px) {
	html,
	body {
		overflow-x: hidden;
	}

	.navbar {
		justify-content: space-between;
		/* overflow-x: hidden; */
	}

	.logo {
		padding-left: 10vw;
	}

	.burger-wraper {
		width: 50%;
		display: flex;
		justify-content: space-around;
	}

	.nav-links {
		position: absolute;
		right: 0px;
		height: 75vh;
		top: 7.8vh;
		background-color: #007fff;
		display: flex;
		flex-direction: column;
		align-items: center;
		transform: translateX(100%);
		transition: transform 0.5s ease-in;
		z-index: 12;
	}

	.nav-links li {
		opacity: 0;
	}

	.burger {
		display: block;
		cursor: pointer;
		z-index: 12;
	}
}

.nav-active {
	transform: translateX(0%);
}

@keyframes navLinkFade {
	from {
		opacity: 0;
		transform: translateX(50px);
	}
	to {
		opacity: 1;
		transform: translateX(0px);
	}
}

.toggle .line1 {
	transform: rotate(-45deg) translate(-5px, 6px);
}

.toggle .line2 {
	opacity: 0;
}

.toggle .line3 {
	transform: rotate(45deg) translate(-5px, -6px);
}
