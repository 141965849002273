.gallery {
	width: 80vw;
	margin: 30px auto;
}

.item img {
	border-radius: 5px;
	border: 5px solid #007fff;
	border-radius: 10px;
}
