#pricing .title {
	margin-top: -17px !important;
}

.pricing-content {
	display: flex;
	justify-content: space-around;
	align-items: flex-start;
}

.pricing-content .accordion {
	width: 80vw;
}

.accordion__content div {
	display: flex;
	justify-content: space-between;
	padding-bottom: 10px;
	margin-bottom: 10px;
	border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.accordion__content div span {
	color: #007fff;
	font-weight: bold;
}

@media screen and (max-width: 935px) {
	.accordion__content div div {
		flex-direction: column;
	}
}

.accordion__content div div {
	border: none;
	margin: 0;
	padding: 0;
}

.accordion__content div div p {
	margin-right: 4px;
}

/* Accordion */

.accordion {
	width: 50vw;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
	border-radius: 5px;
	overflow: hidden;
	background-color: #007fff;
}

.accordion__label,
.accordion__content {
	padding: 14px 20px;
}

.accordion__label {
	display: block;
	color: #f5fafa;
	font-weight: 500;
	cursor: pointer;
	position: relative;
	transition: background 0.1s;
}

@media screen and (min-width: 450px) {
	.accordion__label:hover {
		background: rgba(255, 255, 255, 0.1);
	}
}

.accordion__label::after {
	content: "";
	position: absolute;
	top: 50%;
	right: 20px;
	transform: translateY(-50%);
	width: 12px;
	height: 6px;
	background-image: url('data:image/svg+xml;utf8,<svg width="100" height="50" xmlns="http://www.w3.org/2000/svg"><polygon points="0,0 100,0 50,50" style="fill:%23FFFFFF99;" /></svg>');
	background-size: contain;
	transition: transform 0.4s;
}

.accordion__content {
	background-color: #f5fafa;
	line-height: 1.6;
	letter-spacing: 0.5px;
	font-weight: 300;
	display: none;
}

.accordion__input {
	display: none;
}

.accordion__input:checked ~ .accordion__content {
	display: block;
	font-size: 0.9em;
}

.accordion__input:checked ~ .accordion__label::after {
	transform: translateY(-50%) rotate(0.5turn);
}

/*about-card*/

#pricing-card {
	border-radius: 5px !important;
	margin-top: 50px !important;
	font-size: 16px !important;
	font-weight: 500 !important;
}

#pricing-card {
	background-color: #007fff;
	color: #f5fafa;
	padding: 20px;
	margin: 0 auto;
	width: 80vw;
}

#pricing-card p {
	margin-bottom: 10px;
	/* text-align: justify; */
}
