* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-family: "Poppins", sans-serif;
	scroll-behavior: smooth;
}

body {
	background-color: #f5fafa;
	height: 100vh;
	font-family: "Poppins", sans-serif;
	scroll-behavior: smooth;
}

/* Contact me page css */

#myVideo {
	max-width: 100%;
	filter: blur(0px);
	background-size: cover;
}

.title {
	margin: 30px 0 30px 0;
	text-align: center;
	font-size: 25px;
}

.title h1 {
	font-weight: 500;
	font-size: 1.5em;
}
