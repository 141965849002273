#doctors {
	/* margin-top: -50px; */
}

.doctor-card {
	width: 80vw;
	margin: 30px auto;
}

.doctor-card img {
	border: 5px solid #007fff;
	border-radius: 10px;
	margin-bottom: 10px;
	width: 100%;
}

.doctor-card div {
	margin: auto;
}

.doctor-card div h2 {
	margin-bottom: 10px;
	font-weight: 500;
}

.doctor-card p {
	/* text-align: justify; */
}

@media screen and (min-width: 600px) {
	#doctors {
		/* margin-top: -80px; */
	}

	.title {
		margin-bottom: 30px;
	}

	.doctor-card {
		display: flex;
		justify-content: space-between;
	}

	.doctor-card img {
		width: 40%;
	}

	.doctor-card div {
		width: 50%;
	}
}

@media screen and (min-width: 900px) {
	#doctors {
		/* margin-top: -100px; */
	}

	.doctor-card {
		max-width: 850px;
		margin-bottom: 0;
		margin-top: 0;
	}
}

@media screen and (min-width: 1400px) {
	#doctors {
		/* margin-top: -150px; */
	}
}
