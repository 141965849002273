.footer {
	background-color: #007fff;
	display: flex;
	justify-content: center;
	margin-top: 30px;
	height: 8vh;
}

.footer p {
	margin: auto;
	color: #f5fafa;
	font-size: 0.85em;
}
