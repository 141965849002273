.contact {
	display: flex;
	justify-content: center;
	margin-bottom: 50px;
}

.details {
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	margin-right: 40px;
}

.info {
	display: flex;
	margin: 15px;
}

.icon-box {
	background-color: #007fff;
	height: 55px;
	width: 55px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.icon-box i {
	color: #f5fafa;
}

.content {
	padding-left: 20px;
}

.content h4 {
	margin-bottom: 5px;
	font-weight: 500;
}

.content p span {
	font-weight: 600;
}

/* Google Maps */
#contact iframe {
	border: 5px solid #007fff;
}

@media screen and (max-width: 1000px) {
	iframe {
		width: 90vw;
	}

	.contact {
		flex-direction: column;
		align-items: center;
	}

	.details {
		margin-bottom: 30px;
	}

	.content h4 {
		font-size: 15px;
	}

	.content p {
		font-size: 13px;
	}

	.details {
		flex-direction: row;
	}
}

@media screen and (max-width: 825px) {
	.contact {
		margin-left: 30px;
		align-items: flex-start;
	}

	.details {
		flex-direction: column;
		align-items: flex-start;
	}
}

@media screen and (max-width: 450px) {
	iframe {
		width: 85vw;
	}
}
