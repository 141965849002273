.about {
	background-color: #007fff;
	color: #f5fafa;
	display: flex;
	justify-content: space-around;
	font-size: 25px;
	margin-top: -1px;
}

.about div {
	margin: auto;
}

.about h1 {
	font-weight: 500;
	font-size: 1.5em;
}

.about p {
	font-size: 20px;
	width: 30vw;
	/* text-align: justify; */
}

.about img {
	width: 50vw;
}

.svg {
	width: 100vw;
	margin-top: -2px;
}

.about-card {
	background-color: #007fff;
	color: #f5fafa;
	border-radius: 10px;
	font-size: 25px;
	padding: 50px;
	margin: 0 auto;
	width: 80vw;
}

.about-card p {
	margin-bottom: 10px;
	font-size: 20px;
	/* text-align: justify; */
}

@media screen and (max-width: 745px) {
	.about div {
		margin-top: 30px;
		margin-bottom: 50px;
	}

	.about p {
		width: 80vw;
	}

	.about img {
		display: none;
	}
}

@media screen and (max-width: 935px) {
	.about h1 {
		font-size: 1.3em;
	}

	.about p {
		font-size: 0.7em;
	}

	.about-card {
		padding: 20px;
	}

	.about-card p {
		font-size: 0.7em;
	}
}
